import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isSpecialLabel } from '../common/Labels';

const ActivityLabels = ({ activity }) => {
  const isActivitySpecialLabel = isSpecialLabel(activity.activityType);
  const clsType = classNames(
    'o-od-item-card__label u-od-txt--card-type text-center u-od-cs-txt--light u-od-margin-bottom--small',
    {
      'u-od-cs-bg--dark-blue': !isActivitySpecialLabel,
      'u-od-cs-bg--blue': isActivitySpecialLabel,
    }
  );

  return (
    <div className="o-od-activity__labels">
      <div className={clsType}>{activity.activityType}</div>
      <div className="u-od-txt--h3 u-od-margin-bottom--small">
        {activity.focus}
      </div>
    </div>
  );
};

ActivityLabels.propTypes = {
  activity: PropTypes.object.isRequired,
};

export default ActivityLabels;
