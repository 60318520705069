import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { groupBy, isEmpty, keys, map } from 'lodash';
import { renderNoMaterials } from 'components/common/TextMaterialHelpers';
import TextsList from './TextsList';

const GroupedTextsList = ({ textList, resourceType }) => {
  if (isEmpty(textList)) return renderNoMaterials('text', resourceType);

  const groupedTexts = groupBy(textList, 'textType');
  const sortedKeys = keys(groupedTexts).sort();
  return map(sortedKeys, header => (
    <Fragment key={`fr-${header}`}>
      <h3 className="u-od-txt--materials-header">{header}</h3>
      <TextsList textList={groupedTexts[header]} resourceType={resourceType} />
    </Fragment>
  ));
};

GroupedTextsList.propTypes = {
  textList: PropTypes.arrayOf(PropTypes.object).isRequired,
  resourceType: PropTypes.string.isRequired,
};

export default GroupedTextsList;
