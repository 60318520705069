import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../../utils/i18n.js.erb';
import GroupedMaterials from '../common/GroupedMaterials';

const LessonMaterials = ({ resource }) => {
  return (
    <div className="row u-od-full-width">
      <aside className="o-od-panel__aside">
        <h2>{I18n.t('ui.lesson.tabs.materials')}</h2>
      </aside>
      <GroupedMaterials resource={resource} resourceType="lesson" />
    </div>
  );
};

LessonMaterials.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default LessonMaterials;
