import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { loadActivityPage, loadResourcePage } from 'actions';
import {
  getBackgroundUrls,
  getGuidebookType,
  getResourceSlug,
  getTitle,
} from '../selectors';
import { cleanedSlug } from 'routes';
import Layout from './Layout';
import ActivityContainer from './activity/ActivityContainer';
import ResourceContainer from './resource/ResourceContainer';

class ResourcePage extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    rest: PropTypes.object.isRequired,
    backgroundUrls: PropTypes.arrayOf(PropTypes.string),
    resourceSlug: PropTypes.string,
    guidebookType: PropTypes.string,
    title: PropTypes.string,
    // actions
    loadActivityPage: PropTypes.func.isRequired,
    loadResourcePage: PropTypes.func.isRequired,
  };

  initialize(meta = {}) {
    const { resourceType } = this.props.rest;
    if (resourceType === 'activity') {
      this.props.loadActivityPage(this.props.match.params, meta);
    } else {
      this.props.loadResourcePage(this.props.match.params, {
        ...meta,
        resourceType,
      });
    }
  }

  componentDidMount() {
    this.initialize({ source: 'init' });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.match.params, this.props.match.params)) {
      this.initialize();
    }
  }

  render() {
    const { resourceType, withSubNavBar, withTopBar } = this.props.rest;
    const isLoaded =
      cleanedSlug(this.props.resourceSlug) ===
      cleanedSlug(this.props.match.url);
    return (
      <Layout
        backgroundUrls={this.props.backgroundUrls}
        isLoaded={isLoaded}
        guidebookType={
          resourceType === 'guidebook' ? this.props.guidebookType : ''
        }
        title={this.props.title}
        withSubNavBar={withSubNavBar}
        withTopBar={withTopBar}
        resourceType={resourceType}
      >
        {resourceType === 'activity' ? (
          <ActivityContainer />
        ) : (
          <ResourceContainer resourceType={resourceType} />
        )}
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    backgroundUrls: getBackgroundUrls(state),
    guidebookType: getGuidebookType(state),
    resourceSlug: getResourceSlug(state),
    title: getTitle(state.resource, 'title'),
  };
};

export default connect(mapStateToProps, { loadActivityPage, loadResourcePage })(
  ResourcePage
);
