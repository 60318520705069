import { connect } from 'react-redux';
import { loadFullScreen } from 'actions';
import Activity from 'components/activities/Activity';

const mapStateToProps = state => {
  return {
    devise: state.devise,
    meta: state.meta,
    resource: state.resource,
  };
};

const ActivityContainer = connect(mapStateToProps, {
  loadFullScreen,
})(Activity);

export default ActivityContainer;
