import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'utils/i18n.js.erb';
import GroupedTextsList from 'components/common/GroupedTextsList';

const LessonTexts = ({ resource }) => {
  return (
    <div className="row u-od-full-width">
      <aside className="o-od-panel__aside o-od-panel__aside--section">
        <h2>{I18n.t('ui.lesson.tabs.texts')}</h2>
      </aside>
      <section className="o-od-panel__content">
        <GroupedTextsList textList={resource.texts} resourceType="lesson" />
      </section>
    </div>
  );
};

LessonTexts.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default LessonTexts;
