import { connect } from 'react-redux';
import { getGuidebook, getLevelUpPath } from '../../selectors';
import Header from '../../components/nav/Header';

const mapStateToProps = (state, ownProps) => {
  return {
    resource:
      ownProps.resourceType === 'grade' ? state.resource : getGuidebook(state),
    pathLevelUp: getLevelUpPath(state),
    resourceType: ownProps.resourceType,
  };
};

export default connect(mapStateToProps)(Header);
