import React from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { useSelector } from 'react-redux';
import { getIsStudentSubdomain } from '../../selectors';
import { sidebarMaterialsList } from './SidebarMaterials';
import I18n from '../../utils/i18n.js.erb';
import { rawHtml } from '../../utils/Formatters';

const STUDENT_FILTER_MATERIAL = 'ep';

const GuidebookCulminatingTask = ({ resource }) => {
  const isStudentSubdomain = useSelector(getIsStudentSubdomain);
  const materials = isStudentSubdomain
    ? filter(
        resource.culminatingTaskMaterials,
        m => m.type !== STUDENT_FILTER_MATERIAL
      )
    : resource.culminatingTaskMaterials;

  return (
    <div className="row u-od-full-width">
      <aside className="o-od-panel__aside">
        <h2>{I18n.t('ui.guidebook.tabs.culminating_task')}</h2>
        <ul className="u-od-padding-left--gutter2x u-od-margin-top--large no-bullet">
          {sidebarMaterialsList(materials)}
        </ul>
      </aside>
      <section className="o-od-panel__content">
        {rawHtml(resource.metadata.culminatingTask, 'u-od-txt--content')}
      </section>
    </div>
  );
};

GuidebookCulminatingTask.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default GuidebookCulminatingTask;
