import { get, replace, startsWith } from 'lodash';
import * as ActionTypes from '../actions';
import * as EventTypes from '../actions/internal';

const defaultState = {};

export default function loaders(state = defaultState, action) {
  if (action.type === ActionTypes.API_FAILURE) return { [action.type]: true };
  if (action.type === EventTypes.SIGN_OUT) return defaultState;
  if (get(action.meta, 'source') !== 'init' || startsWith(action.type, 'LOAD_'))
    return defaultState;
  const actionKey = replace(action.type, /(_RECEIVE|_REQUEST)/, '');
  if (/_RECEIVE$/.test(action.type))
    return {
      ...state,
      ...{ [actionKey]: false, [ActionTypes.API_FAILURE]: false },
    };
  if (/_REQUEST$/.test(action.type))
    return {
      ...state,
      ...{ [actionKey]: true, [ActionTypes.API_FAILURE]: false },
    };
}
