import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { linkWithParams } from 'routes';

const ActivityControls = ({ activity }) => (
  <ul className="o-od-slide-control">
    <li className="shrink">
      <ul className="o-od-slide-control__items">
        <li>
          {activity.nav.previous && (
            <Link
              to={{
                pathname: linkWithParams(
                  activity.nav.previous.odellCurriculumType,
                  activity.nav.previous.hierarchy
                ),
              }}
            >
              <i
                className="u-od-txt--icon-base od-icon-chevron-left"
                aria-hidden="true"
              ></i>
            </Link>
          )}
        </li>
      </ul>
    </li>
    <li className="shrink">
      <ul className="o-od-slide-control__items">
        <li>
          {activity.nav.next && (
            <Link
              to={{
                pathname: linkWithParams(
                  activity.nav.next.odellCurriculumType,
                  activity.nav.next.hierarchy
                ),
              }}
            >
              <i
                className="u-od-txt--icon-base od-icon-chevron-right"
                aria-hidden="true"
              ></i>
            </Link>
          )}
        </li>
      </ul>
    </li>
  </ul>
);

ActivityControls.propTypes = {
  activity: PropTypes.object.isRequired,
};

export default ActivityControls;
