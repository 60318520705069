import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { linkWithParams, isIwu } from '../../routes';
import { thumbnailUrlWithPreload } from '../../utils/Formatters';

const GradeGuidebook = ({ guidebook, title }) => {
  const link = guidebook.id
    ? { pathname: linkWithParams('guidebook', guidebook.hierarchy) }
    : '#';

  let className = 'o-od-card-guidebook__cover';
  if (isIwu()) {
    className += ' o-od-card-guidebook__iwu';
  }
  return (
    <li className="o-od-card-guidebook__wrap columns small-12 medium-6 large-3 u-od-margin-bottom--gutter2x">
      <Link
        className="o-od-card u-od-custom-link o-od-card-guidebook"
        to={link}
      >
        <div className="o-od-card-guidebook__label u-od-cs-bg--light">
          <h3>{title}</h3>
        </div>
        <div
          className={className}
          style={{ backgroundImage: thumbnailUrlWithPreload(guidebook) }}
        ></div>
      </Link>
    </li>
  );
};

GradeGuidebook.propTypes = {
  guidebook: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default GradeGuidebook;
