import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';
import classNames from 'classnames';
import { getSegment } from './ActivityHelpers';
import ActivitySlides from './ActivitySlides';
import ActivitySlidesControls from './ActivitySlidesControls';

class ActivityContent extends Component {
  static propTypes = {
    meta: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired,
    // actions
    loadFullScreen: PropTypes.func.isRequired,
  };

  toggleFullScreen = _e => {
    this.props.loadFullScreen(!this.props.meta.fullScreen);
  };

  render() {
    const { resource, meta } = this.props;
    const { activity, content } = resource;
    const segmentLength = isArray(content.student.segment)
      ? content.student.segment.length
      : 1;
    const currenSegment = getSegment(content, meta.segmentNumber, 'student');
    const segment = {
      idx: meta.segmentNumber,
      data: currenSegment,
      total: segmentLength,
    };
    const clsBase = classNames('o-od-slide u-od-cs-bg--card-bg-dark', {
      'o-od-slide--base': !meta.fullScreen,
    });
    const clsIcon = classNames(
      'u-od-txt--icon-large u-od-pointer',
      { 'od-icon-expand': !meta.fullScreen },
      { 'od-icon-shrink': meta.fullScreen }
    );

    return (
      <div className={clsBase}>
        <div className="o-od-slide__header u-od-link">
          <i className={clsIcon} onClick={this.toggleFullScreen}></i>
        </div>
        <ActivitySlidesControls segment={segment} />
        <ActivitySlides
          activity={{
            idx: meta.activityNumber,
            purpose: activity.readingPurpose.default,
            nav: resource.nav,
          }}
          segment={segment}
          meta={meta}
        />
      </div>
    );
  }
}

export default ActivityContent;
