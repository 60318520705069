import React from 'react';
import PropTypes from 'prop-types';
// NOTE: commenting here and below as we need to remove thumbnails. more likely temporary
// import { thumbnailUrlWithPreload } from 'utils/Formatters';

const GradeProgramResource = ({ programResource }) => {
  return (
    <li className="columns small-12 medium-6 large-3 u-od-margin-bottom--gutter2x">
      <a
        className="o-od-card u-od-custom-link o-od-card-guidebook"
        href={programResource.url}
        target={
          programResource.url === '/literacy-toolbox' ? '_self' : '_blank'
        }
        rel="noopener noreferrer"
      >
        <div className="o-od-card-guidebook__label u-od-cs-bg--light">
          <h3>{programResource.title}</h3>
        </div>
        {/*<div
          className="o-od-card-guidebook__cover"
          style={{ backgroundImage: thumbnailUrlWithPreload(programResource) }}
        ></div>*/}
      </a>
    </li>
  );
};

GradeProgramResource.propTypes = {
  programResource: PropTypes.object.isRequired,
};

export default GradeProgramResource;
