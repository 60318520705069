import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../../utils/i18n.js.erb';
import { getSegment } from './ActivityHelpers';
import { rawHtml } from '../../utils/Formatters';

const TeacherNotes = ({ content, segmentNumber }) => {
  const teacherSegmentData = getSegment(content, segmentNumber, 'teacher');
  return (
    <div className="o-od-teacher-notes">
      <div className="o-od-teacher-notes__header u-od-padding-h--base u-od-txt--tab text-center">
        {I18n.t('ui.activity.teaching_notes')}
      </div>
      <div className="o-od-teacher-notes__body u-od-txt--slide-notes u-od-padding-h--base u-od-padding-v--base">
        {rawHtml(teacherSegmentData)}
      </div>
    </div>
  );
};

TeacherNotes.propTypes = {
  activity: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  segmentNumber: PropTypes.number.isRequired,
};

export default TeacherNotes;
