import { connect } from 'react-redux';
import LiteracyToolbox from '../../components/literacy_toolbox/LiteracyToolbox';

const mapStateToProps = state => {
  return {
    data: state.nav,
    devise: state.devise,
  };
};

const LiteracyTookboxContainer = connect(
  mapStateToProps,
  null
)(LiteracyToolbox);

export default LiteracyTookboxContainer;
