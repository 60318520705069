const REQUEST = 'REQUEST';
const RECEIVE = 'RECEIVE';

function createRequestTypes(base) {
  return [REQUEST, RECEIVE].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
}

// api events
export const API_FAILURE = 'API_FAILURE';
export const AUTH = createRequestTypes('AUTH');
export const BREADCRUMB = createRequestTypes('BREADCRUMB');
export const GRADES = createRequestTypes('GRADES');
export const LITERACY_TOOLBOX = createRequestTypes('LITERACY_TOOLBOX');
export const ACTIVITY = createRequestTypes('ACTIVITY');
export const RESOURCES = createRequestTypes('RESOURCES');

// sagas events
export const LOAD_BREADCRUMB = 'LOAD_BREADCRUMB';
export const LOAD_FULL_SCREEN = 'LOAD_FULL_SCREEN';
export const LOAD_HOME_PAGE = 'LOAD_HOME_PAGE';
export const LOAD_LITERACY_TOOLBOX_PAGE = 'LOAD_LITERACY_TOOLBOX_PAGE';
export const LOAD_ACTIVITY_PAGE = 'LOAD_ACTIVITY_PAGE';
export const LOAD_PAGE = 'LOAD_PAGE';
export const LOAD_RESOURCE_PAGE = 'LOAD_RESOURCE_PAGE';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const action = (type, payload, meta = {}) => {
  return { type, payload, meta };
};

export const rsaa = rType => {
  return {
    request: params => action(rType[REQUEST], { params }),
    success: (payload, meta) => action(rType[RECEIVE], payload, meta),
    failure: error => action(API_FAILURE, { error }),
  };
};

export const authorize = (email, password, remember) =>
  action(LOGIN, { email, password, remember });
export const loadFullScreen = mode => action(LOAD_FULL_SCREEN, mode);
export const loadHomePage = (slug, meta) => {
  return action(LOAD_HOME_PAGE, slug, meta);
};
export const loadLiteracyToolboxPage = (slug, meta) => {
  return action(LOAD_LITERACY_TOOLBOX_PAGE, slug, meta);
};
export const loadActivityPage = (slug, meta) =>
  action(LOAD_ACTIVITY_PAGE, slug, meta);
export const loadResourcePage = (slug, meta) =>
  action(LOAD_RESOURCE_PAGE, slug, meta);
export const logout = (error = {}) => action(LOGOUT, error);
