import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { gaPageView } from '../utils/analytics';
import { useBodyBackgroundTyped, useDocumentTitle } from '../hooks';
import TopNavBar from './nav/TopNavBar';
import I18n from '../utils/i18n.js.erb';
import backgroundImage from '../../assets/images/bg/G09.jpg';
import backgroundImagePreload from '../../assets/images/bg/preload/G09.jpg';
import Announcement from '../components/Announcement';

const pageTitles = {
  about: 'About',
  about_iwu: 'About',
  privacy: 'Privacy policy',
  terms: 'Terms and Conditions',
};

const StaticPage = ({ rest }) => {
  const { pageType } = rest;
  const title = pageTitles[pageType];

  useDocumentTitle(`ODELL - ${title}`);
  useBodyBackgroundTyped([backgroundImagePreload, backgroundImage], false);

  useEffect(() => {
    gaPageView(title);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="o-od-sticky o-od-sticky--top">
        <TopNavBar />
        <div className="o-od-subheader--grade o-od-page__wrap row u-od-cs-bg--page-header">
          <div className="columns small-12 u-od-flex-row align-middle">
            <h1>{title}</h1>
          </div>
        </div>
      </div>
      <main className="o-od-page__wrap row u-od-cs-bg--page-content o-od-sticky__container--grade u-od-margin-bottom--huge">
        <Announcement />
        <div
          className={`columns c-od-static-page u-od-padding-top--base u-od-padding-bottom--huge c-od-static-page--${pageType}`}
          dangerouslySetInnerHTML={{
            __html: I18n.t(`ui.static_pages.${pageType}_html`),
          }}
        ></div>
      </main>
    </div>
  );
};

StaticPage.propTypes = {
  rest: PropTypes.object.isRequired,
};

export default StaticPage;
