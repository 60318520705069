import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import {
  renderTabKeys,
  renderTabPanels,
  renderTabs,
} from '../common/TabHelpers';
import { isStudentSubdomain } from '../../routes';
import LessonOverview from './LessonOverview';
import LessonLearningGoals from './LessonLearningGoals';
import LessonTexts from './LessonTexts';
import LessonMaterials from './LessonMaterials';

const TAB_KEYS = [
  { key: 'overview', component: LessonOverview },
  { key: 'learning_goals', component: LessonLearningGoals },
  { key: 'texts', component: LessonTexts },
  { key: 'materials', component: LessonMaterials },
];

const Lesson = ({ devise, resource }) => {
  if (isEmpty(resource) || get(resource, 'odellCurriculumType') !== 'lesson')
    return null;
  const isStudent = isStudentSubdomain(devise);
  const tabs = renderTabKeys(TAB_KEYS, 'lesson', 'small-6 large-3');
  const tabPanels = renderTabPanels(TAB_KEYS, resource, { isStudent });
  return renderTabs(tabs, tabPanels);
};

Lesson.propTypes = {
  devise: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
};

export default Lesson;
