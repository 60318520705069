import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import I18n from '../../utils/i18n.js.erb';
import { rawHtml } from '../../utils/Formatters';
import LessonCard from './LessonCard';

const Section = ({ resource }) => {
  const lessons = map(resource.children, s => (
    <LessonCard key={`sl-${s.id}`} resource={s} />
  ));

  return (
    <div className="row u-od-full-width">
      <aside className="o-od-panel__aside o-od-panel__aside--section">
        <h2>{I18n.t('ui.section.tabs.overview')}</h2>
      </aside>
      <section className="o-od-panel__content">
        {resource.titleWeb && rawHtml(resource.titleWeb, 'u-od-txt--content')}
        {rawHtml(resource.teaser, 'u-od-txt--content')}
        <ul className="u-od-margin-top--large no-bullet">{lessons}</ul>
      </section>
    </div>
  );
};

Section.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default Section;
