import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'utils/i18n.js.erb';
import { rawHtml } from 'utils/Formatters';

const LessonLearningGoals = ({ resource }) => {
  return (
    <div className="row u-od-full-width">
      <aside className="o-od-panel__aside">
        <h2>{I18n.t('ui.lesson.tabs.learning_goals')}</h2>
      </aside>
      <section className="o-od-panel__content">
        {rawHtml(resource.lookFors, 'u-od-txt--content')}
      </section>
    </div>
  );
};

LessonLearningGoals.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default LessonLearningGoals;
