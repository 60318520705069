import React from 'react';
import PropTypes from 'prop-types';
import { get, filter } from 'lodash';
import { useSelector } from 'react-redux';
import { getIsStudentSubdomain } from 'selectors';
import { sidebarMaterialsList } from './SidebarMaterials';
import I18n from 'utils/i18n.js.erb';
import { rawHtml } from 'utils/Formatters';
import { isIwu } from '../../routes';

const MATERIALS = { student: 'to_student', teacher: 'to' };

const GuidebookTextOverview = ({ resource }) => {
  const isStudentSubdomain = useSelector(getIsStudentSubdomain);
  const materials = filter(
    resource.textOverviewMaterials,
    m => m.type === MATERIALS[isStudentSubdomain ? 'student' : 'teacher']
  );

  return (
    <div className="row u-od-full-width">
      <aside className="o-od-panel__aside">
        <h2>{I18n.t(`ui.guidebook.tabs.${isIwu() ? 'iwu' : 'ela'}.texts`)}</h2>
        <ul className="u-od-padding-left--gutter2x u-od-margin-top--large no-bullet">
          {sidebarMaterialsList(materials)}
        </ul>
      </aside>
      <section className="o-od-panel__content">
        {rawHtml(
          get(resource, 'metadata.textOverview', ''),
          'u-od-txt--content'
        )}
      </section>
    </div>
  );
};

GuidebookTextOverview.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default GuidebookTextOverview;
