import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import breadcrumb from './breadcrumb';
import devise from './devise';
import errorMessage from './errorMessage';
import loaders from './loaders';
import meta from './meta';
import nav from './nav';
import resource from './resource';

const reducer = history =>
  combineReducers({
    router: connectRouter(history),
    breadcrumb,
    devise,
    errorMessage,
    loaders,
    meta,
    nav,
    resource,
  });

export default reducer;
