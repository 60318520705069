import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, map, sortBy, indexOf, lowerCase } from 'lodash';
import I18n from '../../utils/i18n.js.erb';
import {
  materialIcon,
  MATERIAL_TYPES,
  MATERIAL_DOWNLOAD_ICONS,
  MATERIAL_TYPE_PRIORITY,
  renderNoMaterials,
} from './TextMaterialHelpers';

const materialLinks = material => {
  return map(MATERIAL_TYPES, t => {
    const gdoc_url = url => {
      if (typeof url === 'string') {
        const id = url.match(/https:\/\/drive\.google\.com\/open\?id=(.*)/i)[1];
        return `https://docs.google.com/document/d/${id}/copy`;
      } else {
        return url;
      }
    };
    const url = get(material.links, t);
    if (url) {
      const iconTitle = `${t === 'gdoc' ? 'Open' : 'Download'} ${
        material.title
      }`;
      const iconDef =
        t === 'gdoc'
          ? get(MATERIAL_DOWNLOAD_ICONS, t)
          : get(MATERIAL_DOWNLOAD_ICONS, material.type) ||
            get(MATERIAL_DOWNLOAD_ICONS, t);
      return (
        <li key={`mlg-${material.id}-${t}`}>
          <a
            href={t === 'gdoc' ? gdoc_url(url) : url}
            target="_blank"
            rel="noopener noreferrer"
            title={iconTitle}
            className="u-od-txt--material--download"
          >
            {materialIcon(iconDef, iconTitle)}
          </a>
        </li>
      );
    }
    return null;
  });
};

const MaterialsList = ({ materials, resourceType }) => {
  const iterates =
    resourceType === 'LiteracyToolboxItems'
      ? [m => lowerCase(m.title)]
      : [
          m => -indexOf(MATERIAL_TYPE_PRIORITY, m.type),
          m => lowerCase(m.title),
        ];
  const downloads = map(sortBy(materials, iterates), m => {
    return (
      <tr key={`mlg-${m.id}`}>
        <td className="o-od-textlist__icon text-center">
          <ul className="u-od-txt--materials__links">{materialLinks(m)}</ul>
        </td>
        <td>
          <span>{m.title}</span>
        </td>
      </tr>
    );
  });
  if (isEmpty(downloads)) return renderNoMaterials('material', resourceType);
  return (
    <table className="o-od-textlist u-od-txt--materials u-od-margin-bottom--large">
      <thead>
        <tr>
          <th>{I18n.t('ui.table.materials.header.links')}</th>
          <th>{I18n.t('ui.table.materials.header.title')}</th>
        </tr>
      </thead>
      <tbody>{downloads}</tbody>
    </table>
  );
};

MaterialsList.propTypes = {
  materials: PropTypes.arrayOf(PropTypes.object).isRequired,
  resourceType: PropTypes.string.isRequired,
};

export { materialLinks, MaterialsList };
