import { schema } from 'normalizr';

const activity = new schema.Entity('activities');

const lesson = new schema.Entity('lessons', {
  children: [activity],
});

const section = new schema.Entity('sections', {
  children: [lesson],
});

const data = new schema.Array(section);

const breadcrumb = {
  data: data,
};

// Schemas for some API responses.
export const Schemas = {
  BREADCRUMB: breadcrumb,
};
