import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import SubBar from 'components/nav/SubBar';

const mapStateToProps = state => {
  return {
    breadcrumb: state.breadcrumb,
  };
};

const SubNavBar = connect(mapStateToProps, { push })(SubBar);

export default SubNavBar;
