import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { linkWithParams } from '../../routes';
import { rawHtml } from '../../utils/Formatters';
import { isSpecialLabel } from '../common/Labels';
import I18n from '../../utils/i18n.js.erb';

const ActivityCard = ({ isStudent, resource }) => {
  const activity = resource.activity;
  const isActivitySpecialLabel = isSpecialLabel(activity.activityType);
  const clsType = classNames(
    'o-od-item-card__label u-od-txt--card-type text-center u-od-cs-txt--light',
    {
      'u-od-cs-bg--dark-blue': !isActivitySpecialLabel,
      'u-od-cs-bg--blue': isActivitySpecialLabel,
    }
  );
  return (
    <li className="o-od-card o-od-item-card u-od-cs-bg--card-bg-dark u-od-custom-link">
      <Link to={{ pathname: linkWithParams('activity', resource.hierarchy) }}>
        <div className="row is-collapse-child align-middle align-justify">
          <h3 className="u-od-txt--card-header">
            {I18n.t('ui.hierarchy.activity', { num: activity.number })}
          </h3>
          {!isStudent && <div className={clsType}>{activity.activityType}</div>}
        </div>
        <div className="row is-collapse-child align-middle align-justify">
          <span className="u-od-txt--h3">{activity.focus}</span>
          {!isStudent && (
            <>
              {isEmpty(activity.monitorMoment) ? null : (
                <span className="u-od-txt--h2 u-od-txt--upcase">
                  {activity.monitorMoment}
                </span>
              )}
            </>
          )}
        </div>
        <div className="u-od-margin-top--small">
          {rawHtml(
            activity.aboutTheActivityStudent.default,
            'u-od-txt--card u-od-margin-top--small',
            'p'
          )}
        </div>
      </Link>
    </li>
  );
};

ActivityCard.propTypes = {
  isStudent: PropTypes.bool.isRequired,
  resource: PropTypes.object.isRequired,
};

export default ActivityCard;
