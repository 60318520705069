import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { includes, map, isEmpty, compact, kebabCase } from 'lodash';
import { useLocation } from 'react-router';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import classNames from 'classnames';
import I18n from '../../utils/i18n.js.erb';
import {
  appNamedRoutes,
  isStudentSubdomain,
  staticRoutes,
  studentsPath,
} from '../../routes';
import LogoHeader from '../../../assets/images/layout/logo-header.svg';
import LogoHeaderOneLine from '../../../assets/images/layout/logo-header-1line.svg';
import LogoHeaderIwu from '../../../assets/images/layout/logo-header-iwu.svg';
import LogoHeaderOneLineIwu from '../../../assets/images/layout/logo-header-1line-iwu.svg';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

const HEADER_LOGO_EXPANDED = ['top', 'grade', 'guidebook'];

const hamurgerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="3" y1="12" x2="21" y2="12" />
    <line x1="3" y1="6" x2="21" y2="6" />
    <line x1="3" y1="18" x2="21" y2="18" />
  </svg>
);

/* eslint-disable jsx-a11y/anchor-is-valid */
const TopBar = ({ devise, resourceType, logout, isIwu }) => {
  const headerType = resourceType || 'top';
  const currentPath = useLocation().pathname;
  const isBaseLogo = includes(HEADER_LOGO_EXPANDED, headerType);
  let logoHeader;
  if (isIwu) {
    logoHeader = isBaseLogo ? LogoHeaderIwu : LogoHeaderOneLineIwu;
  } else {
    logoHeader = isBaseLogo ? LogoHeader : LogoHeaderOneLine;
  }
  const logoCls = isBaseLogo ? 'base' : 'line';
  const clsFullMenu = classNames('columns shrink', {
    'show-for-medium': !isStudentSubdomain(devise),
  });
  const clsHamburgerMenu = classNames('columns shrink', {
    'hide-for-medium': !isStudentSubdomain(devise),
    'hide': isStudentSubdomain(devise), // prettier-ignore
  });
  const navProgramResourcesStatic = {
    about: isIwu ? staticRoutes.about_iwu : staticRoutes.about,
    literacy_toolbox: staticRoutes.literacy_toolbox,
  };
  const programResources =
    !isStudentSubdomain(devise) && devise.loggedIn
      ? compact(
          map(window.appConf.navProgramResources, (v, _k) => {
            if (isEmpty(v.url)) return null;
            const url = navProgramResourcesStatic[v.url] || v.url;
            const cls = classNames('o-od-nav-dropdown__item', {
              'o-od-nav-dropdown__item--active': currentPath === url,
            });
            return (
              <MenuItem
                className={cls}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                key={`pr-${kebabCase(v.title)}`}
              >
                {v.title}
              </MenuItem>
            );
          })
        )
      : null;

  return (
    <header className="u-od-txt--header u-od-cs-txt--light u-od-cs-bg--primary">
      <nav
        className={`o-od-page__wrap o-od-header--${headerType} row align-middle`}
        aria-label={I18n.t('ui.a11y.nav.top')}
        role="navigation"
      >
        <div className="columns">
          <Link
            to={isIwu ? appNamedRoutes.iwu_root : appNamedRoutes.root}
            className="u-od-custom-link"
          >
            <img
              className={`o-od-header__logo--${logoCls} u-od-margin-right--small`}
              src={logoHeader}
              alt="ODELL"
            />
          </Link>
        </div>
        <div className={clsFullMenu}>
          {isStudentSubdomain(devise) ? (
            <>
              <Link
                to={navProgramResourcesStatic.literacy_toolbox}
                target="_blank"
              >
                <span>{I18n.t('ui.nav.literacy_toolbox')}</span>
              </Link>
              <Link
                className="u-od-margin-left--large"
                to={navProgramResourcesStatic.about}
              >
                <span>{I18n.t('ui.nav.about')}</span>
              </Link>
            </>
          ) : null}
          {/*<Link*/}
          {/*  className="u-od-margin-left--large"*/}
          {/*  to={isIwu ? appNamedRoutes.root : appNamedRoutes.iwu_root}*/}
          {/*>*/}
          {/*  <span>{isIwu ? I18n.t('ui.nav.ela') : I18n.t('ui.nav.iwu')}</span>*/}
          {/*</Link>*/}
          {isEmpty(programResources) ? null : (
            <Menu
              menuButton={
                /* eslint-disable react/jsx-no-bind */
                <MenuButton
                  className={({ open }) =>
                    open
                      ? 'o-od-nav-dropdown__button o-od-nav-dropdown__button--open'
                      : 'o-od-nav-dropdown__button'
                  }
                >
                  {I18n.t('ui.nav.program_resources')}
                </MenuButton>
              }
              menuClassName="o-od-nav-dropdown"
            >
              {programResources}
            </Menu>
          )}
          {!isStudentSubdomain(devise) && (
            <a
              className="o-od-header__item"
              href={studentsPath(devise)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>{I18n.t('ui.nav.students')}</span>
            </a>
          )}
          {!isStudentSubdomain(devise) && devise.loggedIn && (
            <button
              className="u-od-cs-txt-link--light u-od-txt--bold o-od-header__item"
              onClick={logout}
              title="Logout"
            >
              {I18n.t('ui.nav.logout')}
            </button>
          )}
        </div>
        <div className={clsHamburgerMenu}>
          <Menu
            menuButton={
              /* eslint-disable react/jsx-no-bind */
              <MenuButton
                className={({ open }) =>
                  open
                    ? 'o-od-nav-dropdown__button o-od-nav-dropdown__button--open'
                    : 'o-od-nav-dropdown__button'
                }
              >
                {hamurgerIcon()}
              </MenuButton>
            }
            menuClassName="o-od-nav-dropdown"
          >
            {isEmpty(programResources) ? null : (
              <SubMenu
                label={I18n.t('ui.nav.program_resources')}
                itemProps={{ className: 'o-od-nav-dropdown__item' }}
                menuClassName="o-od-nav-dropdown"
              >
                {programResources}
              </SubMenu>
            )}
            <MenuItem
              className="o-od-nav-dropdown__item"
              href={studentsPath(devise)}
            >
              {I18n.t('ui.nav.students')}
            </MenuItem>
            {devise.loggedIn && (
              <MenuItem
                className="o-od-nav-dropdown__item u-od-cs-txt-link--light"
                onClick={logout}
                title="Logout"
              >
                {I18n.t('ui.nav.logout')}
              </MenuItem>
            )}
          </Menu>
        </div>
      </nav>
    </header>
  );
};

TopBar.propTypes = {
  devise: PropTypes.object,
  isIwu: PropTypes.bool,
  resourceType: PropTypes.string,
  logout: PropTypes.func,
};

export default TopBar;
