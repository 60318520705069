import React from 'react';
import { map, kebabCase } from 'lodash';
import {
  materialIcon,
  MATERIAL_UNIT_ICONS,
} from 'components/common/TextMaterialHelpers';

export const sidebarMaterialsList = materials =>
  map(materials, (m, idx) => {
    return (
      <li
        key={`gm-${kebabCase(m.title)}-${idx}`}
        className="u-od-flex align-middle u-od-margin-bottom--large"
      >
        <a
          href={m.links.pdf}
          target="_blank"
          rel="noopener noreferrer"
          className="u-od-txt--material--download"
        >
          {materialIcon(MATERIAL_UNIT_ICONS[m.type], `Download ${m.title}`)}
        </a>
        <span className="u-od-padding-left--base u-od-txt--content-material">
          {m.title}
        </span>
      </li>
    );
  });
