// keep it for webpacker to recompile locales
export const gaPageView = title => {
  if (window.ga) {
    ga('set', 'location', document.location.href.split('#')[0]);
    ga('send', 'pageview', { title });
  }
  if (window.gtag) {
    gtag('event', 'page_view', {
      page_title: title,
      page_location: document.location.href.split('#')[0],
    });
  }
};
