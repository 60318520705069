import React from 'react';
import PropTypes from 'prop-types';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useHistory, useLocation } from 'react-router';
import { find, map } from 'lodash';
import I18n from '../../utils/i18n.js.erb';
import LiteracyToolboxItems from './LiteracyToolboxItems';

const TAB_KEYS = [
  { key: 'reference_guides', dataField: 'Reference guides' },
  { key: 'tools', dataField: 'Tools' },
  { key: 'tool_exemplars', dataField: 'Tool Exemplars' },
  { key: 'spanish_tools', dataField: 'Spanish Tools' },
];

const LiteracyToolbox = ({ data }) => {
  const searchParams = useLocation().search;
  const match = searchParams.match(/\?tab=(.*)/);
  const defaultTab = parseInt(match ? match[1] : 0);
  const history = useHistory();

  const tabs = map(TAB_KEYS, tab => (
    <Tab key={`tab-${tab.key}`} className={`columns`} tabIndex="0">
      <div className="o-od-tabs__tab u-od-txt--tab">
        {I18n.t(`ui.literacy_toolbox.tabs.${tab.key}`)}
      </div>
    </Tab>
  ));

  const tabPanels = map(TAB_KEYS, (tab, idx) => {
    const section = find(data.literacyToolbox, { title: tab.dataField });
    let materials = section !== undefined ? section.materials : [];
    return (
      <TabPanel key={`tabpanel-${idx}`} className="o-od-tabs__tab-panel">
        <div className="o-od-card-grade__wrap u-od-flex-row">
          <LiteracyToolboxItems materials={materials} type={tab.key} />
        </div>
      </TabPanel>
    );
  });

  const selectHandler = (index, _lastIndex, _event) => {
    history.replace({
      search: index !== 0 ? `?tab=${index}` : null,
    });

    return true;
  };

  return (
    <Tabs
      defaultIndex={defaultTab}
      className="o-od-tabs"
      /* eslint-disable react/jsx-no-bind */
      onSelect={selectHandler}
      selectedTabClassName="o-od-tabs__tab--selected"
      selectedTabPanelClassName="o-od-tabs__tab-panel--selected"
    >
      <TabList className="o-od-tabs__tab-list row no-bullet">{tabs}</TabList>
      <div>{tabPanels}</div>
    </Tabs>
  );
};

LiteracyToolbox.propTypes = {
  data: PropTypes.object.isRequired,
};

export default LiteracyToolbox;
