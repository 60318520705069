import PropTypes from 'prop-types';
import { get, isEmpty, filter } from 'lodash';
import {
  renderTabKeys,
  renderTabPanels,
  renderTabs,
} from '../common/TabHelpers';
import SectionOverview from './SectionOverview';
import SectionDiagnostic from './SectionDiagnostic';
import SectionCulminatingTask from './SectionCulminatingTask';
import SectionMaterials from './SectionMaterials';
import SectionTexts from './SectionTexts';

const TAB_KEYS = [
  { key: 'overview', component: SectionOverview },
  { key: 'diagnostic', component: SectionDiagnostic },
  { key: 'culminating_task', component: SectionCulminatingTask },
  { key: 'texts', component: SectionTexts },
  { key: 'materials', component: SectionMaterials },
];

const Section = ({ resource }) => {
  if (isEmpty(resource) || get(resource, 'odellCurriculumType') !== 'section')
    return null;
  const tabKeys = filter(TAB_KEYS, tab =>
    resource.last ? tab.key !== 'diagnostic' : tab.key !== 'culminating_task'
  );
  const tabs = renderTabKeys(
    tabKeys,
    'section',
    'o-od-panel-tab--section small-6'
  );
  const tabPanels = renderTabPanels(tabKeys, resource);
  return renderTabs(tabs, tabPanels);
};

Section.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default Section;
