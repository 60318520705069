import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import I18n from '../../utils/i18n.js.erb';
import { rawHtml } from '../../utils/Formatters';
import ActivityCard from './ActivityCard';
import { useSelector } from 'react-redux'; // Import useSelector
import { getIsStudentSubdomain } from '../../selectors'; // Import the selector

const LessonOverview = ({ isStudent, resource }) => {
  const isStudentSubdomain = useSelector(getIsStudentSubdomain); // Use the selector
  const activities = map(resource.activities, s => (
    <ActivityCard key={`sl-${s.id}`} resource={s} isStudent={isStudent} />
  ));

  return (
    <div className="row u-od-full-width">
      <aside className="o-od-panel__aside">
        <h2>{I18n.t('ui.lesson.tabs.overview')}</h2>
        {!isStudentSubdomain && (
          <div className="u-od-txt--card-type u-od-cs-txt--dark-blue">
            {resource.lessonType}
          </div>
        )}
        {!isStudentSubdomain && (
          <div className="row is-collapse-child align-middle align-justify u-od-margin-top--small">
            <span className="u-od-txt--h3">{resource.focus}</span>
          </div>
        )}
      </aside>
      <section className="o-od-panel__content">
        {rawHtml(resource.description, 'u-od-txt--content')}
        <ul className="u-od-margin-top--large no-bullet">{activities}</ul>
      </section>
    </div>
  );
};

LessonOverview.propTypes = {
  isStudent: PropTypes.bool.isRequired,
  resource: PropTypes.object.isRequired,
};

export default LessonOverview;
