import React from 'react';
import PropTypes from 'prop-types';
import { concat, isEmpty } from 'lodash';
import I18n from '../../utils/i18n.js.erb';
import { MaterialsList } from './MaterialsList';

const GroupedMaterials = ({ resource, resourceType }) => {
  const isDx = resource.materials.dx;
  const isTools = resource.materials.tools;
  const isRg = resource.materials.rg;
  const isQs = resource.materials.qs;
  return (
    <section className="o-od-panel__content">
      {isEmpty(resource.materials) && (
        <MaterialsList materials={[]} resourceType={resourceType} />
      )}
      {(isDx || isTools) && (
        <h3 className="u-od-txt--materials-header">
          {I18n.t('ui.section.materials.tools')}
        </h3>
      )}
      {(isDx || isTools) && (
        <MaterialsList
          materials={concat(
            resource.materials.dx || [],
            resource.materials.tools || []
          )}
          resourceType={resourceType}
        />
      )}
      {isRg && (
        <h3 className="u-od-txt--materials-header">
          {I18n.t('ui.section.materials.rg')}
        </h3>
      )}
      {isRg && (
        <MaterialsList
          materials={resource.materials.rg}
          resourceType={resourceType}
        />
      )}
      {isQs && (
        <h3 className="u-od-txt--materials-header">
          {I18n.t('ui.section.materials.qs')}
        </h3>
      )}
      {isQs && (
        <MaterialsList
          materials={resource.materials.qs}
          resourceType={resourceType}
        />
      )}
    </section>
  );
};

GroupedMaterials.propTypes = {
  resource: PropTypes.object.isRequired,
  resourceType: PropTypes.string.isRequired,
};

export default GroupedMaterials;
