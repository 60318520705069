import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import I18n from '../../utils/i18n.js.erb';
import GradeProgramResource from './GradeProgramResource';

const GradeProgramResources = ({ programResources }) => {
  const blocks = map(programResources, (pr, idx) => (
    <GradeProgramResource key={`pr-${pr.type}-${idx}`} programResource={pr} />
  ));
  return (
    <div className="u-od-margin-top--large">
      <h2 className="u-od-txt--upcase">
        {I18n.t(`ui.guidebooks.program_resources`)}
      </h2>
      <hr className="u-od-hline u-od-margin-top--zero u-od-margin-bottom--gutter" />
      <ul className="no-bullet row">{blocks}</ul>
    </div>
  );
};

GradeProgramResources.propTypes = {
  programResources: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default GradeProgramResources;
