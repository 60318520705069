import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import {
  renderTabKeys,
  renderTabPanels,
  renderTabs,
} from '../common/TabHelpers';
import GuidebookOverview from './GuidebookOverview';
import GuidebookCulminatingTask from './GuidebookCulminatingTask';
import GuidebookTextOverview from './GuidebookTextOverview';
import GuidebookMaterials from './GuidebookMaterials';

const TAB_KEYS = [
  { key: 'overview', component: GuidebookOverview },
  { key: 'culminating_task', component: GuidebookCulminatingTask },
  { key: 'texts', component: GuidebookTextOverview },
  { key: 'materials', component: GuidebookMaterials },
];

const Guidebook = ({ resource }) => {
  if (isEmpty(resource) || get(resource, 'odellCurriculumType') !== 'guidebook')
    return null;
  const tabs = renderTabKeys(TAB_KEYS, 'guidebook', 'small-6 large-3');
  const tabPanels = renderTabPanels(TAB_KEYS, resource);
  return renderTabs(tabs, tabPanels);
};

Guidebook.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default Guidebook;
