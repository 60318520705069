import React from 'react';
import { map } from 'lodash';
import { Tab, TabPanel, Tabs, TabList } from 'react-tabs';
import I18n from '../../utils/i18n.js.erb';
import { isIwu } from '../../routes';

const scrollToTop = () => {
  window.scrollTo({ top: 0 });
  [].forEach.call(
    document.getElementsByClassName('o-od-teacher-notes__body'),
    el => el.scrollTo({ top: 0 })
  );
};

const selectHandler = (_index, _lastIndex, _event) => {
  scrollToTop();
  return true;
};

export const renderTabPanels = (tabs, resource, rest = {}) =>
  map(tabs, (tab, idx) => {
    const PanelType = tab.component;
    return (
      <TabPanel key={`tabpanel-${idx}`} className="o-od-tabs__tab-panel">
        <PanelType resource={resource} {...rest} />
      </TabPanel>
    );
  });

export const renderTabKeys = (tabs, type, cls) =>
  map(tabs, tab => (
    <Tab key={`tab-${tab.key}`} className={`columns ${cls}`}>
      <div className="o-od-tabs__tab u-od-txt--tab">
        {I18n.t(
          `ui.${type}.tabs.${
            tab.key === 'texts' && type === 'guidebook'
              ? isIwu()
                ? 'iwu.'
                : 'ela.'
              : ''
          }${tab.key}`
        )}
      </div>
    </Tab>
  ));

export const renderTabs = (tabs, tabPanels) => {
  scrollToTop();
  return (
    <Tabs
      defaultIndex={0}
      className="o-od-tabs"
      onSelect={selectHandler}
      selectedTabClassName="o-od-tabs__tab--selected"
      selectedTabPanelClassName="o-od-tabs__tab-panel--selected"
    >
      <TabList className="o-od-tabs__tab-list row no-bullet">{tabs}</TabList>
      <div>{tabPanels}</div>
    </Tabs>
  );
};
