import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';
import {
  renderTabKeys,
  renderTabPanels,
  renderTabs,
} from '../common/TabHelpers';
import ActivityOverview from './ActivityOverview';
import ActivityMaterials from './ActivityMaterials';
import ActivityTexts from './ActivityTexts';
import LessonLearningGoals from '../lessons/LessonLearningGoals';

const TAB_KEYS = [
  { key: 'overview', component: ActivityOverview },
  { key: 'learning_goals', component: LessonLearningGoals },
  { key: 'texts', component: ActivityTexts },
  { key: 'materials', component: ActivityMaterials },
];

const Activity = props => {
  const { resource, ...rest } = props;
  if (isEmpty(resource) || get(resource, 'odellCurriculumType') !== 'activity')
    return null;
  const tabs = renderTabKeys(TAB_KEYS, 'activity', 'small-6 large-3');
  const tabPanels = renderTabPanels(TAB_KEYS, resource, rest);
  return renderTabs(tabs, tabPanels);
};

Activity.propTypes = {
  devise: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  // actions
  loadFullScreen: PropTypes.func.isRequired,
};

export default Activity;
