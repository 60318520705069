import { isArray, find, get } from 'lodash';

export const getSegment = (data, idx, key) => {
  if (!isArray(data[key].segment) && !data[key].segment) return data[key].data;
  const segments = isArray(data[key].segment)
    ? data[key].segment
    : [data[key].segment];
  const currentSegment = find(segments, ['id', idx]);
  return get(currentSegment, 'data');
};
