import React, { useState } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import I18n from 'utils/i18n.js.erb';
import { rawHtml } from '../utils/Formatters';
import { connect } from 'react-redux';
import { isStudentSubdomain } from '../routes';

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

const getCookieName = () => {
  const rootElement = document.getElementById('root');
  if (!rootElement) return null;
  if (!(rootElement.dataset.showAnncmnt === 'true')) return null;
  return rootElement.dataset.anncmnt;
};

// get cookie value, name of cookie at #root data-anncmnt
const getCookieValue = name => {
  if (!name) return false;
  // get saved cookie value
  const cookieValue = getCookie(name);
  return !(cookieValue && cookieValue.length > 0);
};

const setCookieValue = (name, value) => {
  if (!name) return false;
  document.cookie = `${name}=${value}`;
};

const Announcement = ({ devise }) => {
  const cookieName = getCookieName();
  const isStudent = isStudentSubdomain(devise);
  const [isOpen, toggleOpen] = useState(
    !isStudent && getCookieValue(cookieName)
  );
  const closeAnnouncement = () => {
    toggleOpen(false);
    setCookieValue(cookieName, 'off');
  };
  if (!isOpen) return null;

  ReactModal.setAppElement('body');

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Announcement"
      className="reveal small o-od-reveal"
      overlayClassName="reveal-overlay"
      aria={{
        labelledby: 'anncmnt-heading',
        describedby: 'anncmnt-description',
      }}
    >
      <h1 id="anncmnt-heading">{I18n.t('ui.announcement.heading')}</h1>
      <div id="anncmnt-description" className="o-od-reveal__body">
        {rawHtml(I18n.t('ui.announcement.text_html'))}
      </div>
      <button
        className="close-button"
        aria-label="Close"
        type="button"
        // eslint-disable-next-line react/jsx-no-bind
        onClick={closeAnnouncement}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </ReactModal>
  );
};

Announcement.propTypes = {
  devise: PropTypes.object,
};

const mapStateToProps = state => ({
  devise: state.devise,
});

export default connect(mapStateToProps, {})(Announcement);
