import { connect } from 'react-redux';
import Home from '../../components/home/Home';

const mapStateToProps = state => {
  return {
    data: state.nav,
    devise: state.devise,
  };
};

const HomeContainer = connect(mapStateToProps, null)(Home);

export default HomeContainer;
