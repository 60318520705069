import { connect } from 'react-redux';
import { logout } from '../../actions';
import { isPathIwu } from '../../selectors';
import TopBar from '../../components/nav/TopBar';

const mapStateToProps = state => {
  return {
    devise: state.devise,
    isIwu: isPathIwu(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
  };
};

const TopNavBar = connect(mapStateToProps, mapDispatchToProps)(TopBar);

export default TopNavBar;
