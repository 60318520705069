import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLoaders } from '../selectors';
import { loadHomePage } from '../actions';
import HomeContainer from './home/HomeContainer';
import TopNavBar from './nav/TopNavBar';
import { gaPageView } from '../utils/analytics';
import { useDocumentTitle } from '../hooks';
import ReactResizeDetector from 'react-resize-detector';
import { each } from 'lodash';
import { isIwu } from '../routes';
import Announcement from '../components/Announcement';

const titleHome = 'ODELL';

const HomePage = ({ isLoading, loadHomePage, match }) => {
  const onResize = useCallback((_width, _height) => {
    const header = document.getElementsByTagName('header')[0];
    const footer = document.getElementsByTagName('footer')[0];
    const main = document.getElementsByTagName('main')[0];

    if (!isIwu() || !header || !footer || !main) {
      return false;
    }

    const visibleHeight =
      window.innerHeight - header.scrollHeight - footer.scrollHeight;

    if (visibleHeight !== main.scrollHeight) {
      each(document.getElementsByClassName('c-od-home__grade'), h => {
        if (h.clientWidth === window.innerWidth) {
          h.style.height = (visibleHeight / 2).toString() + 'px';
        } else {
          h.style.height = visibleHeight.toString() + 'px';
        }
      });
    }

    return false;
  }, []);

  useDocumentTitle(titleHome);

  useEffect(() => {
    loadHomePage(match.params);
    gaPageView(titleHome);
  }, [match.params]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="o-od-sticky o-od-sticky--top">
        <TopNavBar />
      </div>
      <Announcement />
      {!isLoading && <HomeContainer />}
      <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
    </div>
  );
};

HomePage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  // actions
  loadHomePage: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    isLoading: getLoaders(state, ['GRADES']),
  };
};

export default connect(mapStateToProps, { loadHomePage })(HomePage);
