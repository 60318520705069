import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grade from '../../components/grades/Grade';
import Guidebook from '../../components/guidebooks/Guidebook';
import Section from '../../components/sections/Section';
import Lesson from '../../components/lessons/Lesson';

const ResourceTypes = {
  grade: Grade,
  guidebook: Guidebook,
  section: Section,
  lesson: Lesson,
};

const ResourceContainer = ({ devise, resource, resourceType }) => {
  const ContentType = ResourceTypes[resourceType];
  return <ContentType resource={resource} devise={devise} />;
};

ResourceContainer.propTypes = {
  devise: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
  resourceType: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    devise: state.devise,
    resource: state.resource,
  };
};

export default connect(mapStateToProps, null)(ResourceContainer);
