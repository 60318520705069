import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'utils/i18n.js.erb';
import GroupedMaterials from 'components/common/GroupedMaterials';

const ActivityMaterials = ({ resource }) => {
  return (
    <div className="row u-od-full-width">
      <aside className="o-od-panel__aside">
        <h2>{I18n.t('ui.activity.tabs.materials')}</h2>
      </aside>
      <GroupedMaterials resource={resource} resourceType="activity" />
    </div>
  );
};

ActivityMaterials.propTypes = {
  resource: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default ActivityMaterials;
