import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { map, range } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { changeActivitySegment } from 'actions/internal';

const ActivitySlidesControls = ({ segment }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const dotSegment = useCallback(
    e => {
      const number = parseInt(e.target.dataset.id, 10);
      dispatch(
        changeActivitySegment(number, segment.idx > number ? 'left' : 'right')
      );
      e.preventDefault();
      const currentPath = location.pathname.replace(/\/step-\d+/, '');
      history.push(`${currentPath}/step-${number}`);
    },
    [segment, dispatch, history, location.pathname]
  );

  const { idx, total } = segment;
  const dots = map(range(total), i => {
    const cls = classNames('u-od-txt--tab o-od-slide-tab__slide-dot', {
      'o-od-slide-tab__slide-dot--active': i + 1 === idx,
      'o-od-slide-tab__slide-dot--inactive': i + 1 !== idx,
    });
    return (
      <li className="columns o-od-slide-tab__item" key={`sdot-${i}`}>
        <button className={cls} onClick={dotSegment} data-id={i + 1}>
          STEP {i + 1}
        </button>
      </li>
    );
  });

  return <ol className="o-od-slide-tab">{segment.total > 1 && dots}</ol>;
};

ActivitySlidesControls.propTypes = {
  segment: PropTypes.object.isRequired,
};

export default ActivitySlidesControls;
