import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import I18n from 'utils/i18n.js.erb';
import { linkWithParams } from 'routes';
import { rawHtml } from 'utils/Formatters';
import { useSelector } from 'react-redux';
import { isSpecialLabel } from '../common/Labels';
import { getIsStudentSubdomain } from '../../selectors';

const LessonCard = ({ resource }) => {
  const isStudentSubdomain = useSelector(getIsStudentSubdomain);
  const isLessonSpecialLabel = isSpecialLabel(resource.lessonType);
  const clsLabel = classNames(
    'o-od-item-card__label u-od-txt--card-type text-center u-od-cs-txt--light',
    {
      'u-od-cs-bg--dark-blue': !isLessonSpecialLabel,
      'u-od-cs-bg--blue': isLessonSpecialLabel,
    }
  );

  return (
    <li className="o-od-card u-od-cs-bg--light u-od-custom-link o-od-item-card columns small-12">
      <Link to={{ pathname: linkWithParams('lesson', resource.hierarchy) }}>
        <div className="row is-collapse-child align-middle align-justify">
          <h3 className="u-od-txt--card-header">
            {I18n.t('ui.hierarchy.lesson', { num: resource.number })}
          </h3>
          {!isStudentSubdomain && (
            <div className={clsLabel}>{resource.lessonType}</div>
          )}
        </div>
        <div className="row is-collapse-child align-middle align-justify">
          {!isStudentSubdomain && (
            <>
              {isEmpty(resource.focus) ? null : (
                <span className="u-od-txt--h3">{resource.focus}</span>
              )}
              {isEmpty(resource.monitorMoment) ? null : (
                <span className="u-od-txt--h2 u-od-txt--upcase">
                  {resource.monitorMoment}
                </span>
              )}
            </>
          )}
        </div>
        <div className="row is-collapse-child align-middle align-justify">
          {rawHtml(
            resource.description,
            'u-od-txt--card u-od-margin-top--small'
          )}
        </div>
      </Link>
    </li>
  );
};

LessonCard.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default LessonCard;
