import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { kebabCase, map } from 'lodash';
import { linkWithParams } from 'routes';

export const getLevelList = (active, data, withSpecialActiveTitle = true) => {
  return map(data, r => {
    const cls = classNames({
      'u-od-cs-bg--page-nav': r.id !== active.id,
      'o-od-breadcrumb--active u-od-cs-bg--secondary u-od-cs-txt-link--light':
        r.id === active.id,
    });
    const pathname = linkWithParams(r.odellCurriculumType, r.hierarchy);
    const title =
      r.id === active.id && withSpecialActiveTitle
        ? r.title
        : `${r.odellCurriculumType[0]}${r.number}`;
    return (
      <li key={`bc-${kebabCase(title)}-${r.id}`} className="o-od-breadcrumb">
        <Link className={cls} to={{ pathname }}>
          {title}
        </Link>
      </li>
    );
  });
};

const BreadCrumbDropdown = ({ active, data, resourceType }) => {
  const [isOpen, toggleOpen] = useState(false);
  const onMouseEnter = useCallback(() => toggleOpen(true), [toggleOpen]);
  const onMouseLeave = useCallback(() => toggleOpen(false), [toggleOpen]);

  const pathname = linkWithParams(active.odellCurriculumType, active.hierarchy);
  const title = `${active.odellCurriculumType[0]}${active.number}`;
  const clsDropdown = classNames(
    `o-od-dropdown-menu no-bullet u-od-cs-txt--txt-black o-od-dropdown-menu--${resourceType}`,
    { 'o-od-dropdown-menu--open': isOpen }
  );
  const list = getLevelList(active, data, false);

  if (active.odellCurriculumType === 'section' && active.skipSections) {
    return false;
  }

  return (
    <li
      className={`o-od-breadcrumb--dropdown o-od-breadcrumb__triangle o-od-breadcrumb__triangle--${resourceType}`}
      onMouseOver={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Link to={{ pathname }}>{title}</Link>
      <ul className={clsDropdown}>{list}</ul>
    </li>
  );
};

BreadCrumbDropdown.propTypes = {
  active: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  resourceType: PropTypes.string.isRequired,
};

export default BreadCrumbDropdown;
