import React from 'react';
import { get } from 'lodash';
import I18n from 'utils/i18n.js.erb';
import iconMaterials from 'assets/images/icons/materials.svg';
import imgNoMaterials from 'assets/images/icons/no-materials.svg';

const materialIcons = {
  // Access Types from text list
  'digital-access': { iconDef: 'digital-access', title: 'Digital Access' },
  'pdf-text': { iconDef: 'pdf-text', title: 'PDF' },
  'purchase-unit-reader': {
    iconDef: 'unit-reader',
    title: 'Unit Reader Purchase',
  },
  'purchase-book': {
    iconDef: 'purchased-tradebook',
    title: 'Purchased Trade Book',
  },
  'purchase-cd': { iconDef: 'dvdcd', title: 'Stream/CD or Stream/DVD' },
  'purchase-dvd': { iconDef: 'dvdcd', title: 'Stream/CD or Stream/DVD' },
};

export const getMaterialIcon = text =>
  get(materialIcons, `${text.accessType}.iconDef`, 'digital-access');

export const getMaterialTitle = text => {
  const k = text.accessType;
  const title =
    k === 'digital-access' || k === 'digital'
      ? text.titleDigitalAccess
      : text.title;
  return get(materialIcons, `${k}.title`, title);
};

export const MATERIAL_UNIT_ICONS = {
  // unit level materials
  cx: 'checklist',
  ep: 'assignment',
  to: 'print-text',
  to_student: 'print-text',
};

export const MATERIAL_TYPE_PRIORITY = ['cpd', 'cp', 'cx', 'dx'];

// NOTE: based on https://docs.google.com/spreadsheets/d/18DgYtGoC8CgYMLNoMDFXBLJ3Rao7-bKEvMPrzLQXyYo/edit#gid=190030330
export const MATERIAL_DOWNLOAD_ICONS = {
  ad: 'onetime-use-tool',
  ar: 'onetime-use-tool',
  aw: 'onetime-use-tool',
  ap: 'onetime-use-tool',
  atp: 'print-text',
  atpg: 'assignment',
  au: 'multi-use-tool',
  cnt: 'multi-use-tool',
  co: 'onetime-use-tool',
  cp: 'multi-use-tool',
  cpd: 'multi-use-tool',
  cr: 'print-text',
  cs: 'onetime-use-tool',
  cx: 'assignment',
  cw: 'multi-use-tool',
  da: 'onetime-use-tool',
  dg: 'onetime-use-tool',
  dg2: 'onetime-use-tool',
  dx: 'assignment',
  ea: 'onetime-use-tool',
  ei: 'onetime-use-tool',
  ep: 'assignment',
  et: 'onetime-use-tool',
  eu: 'onetime-use-tool',
  ew: 'onetime-use-tool',
  ex: 'onetime-use-tool',
  fc: 'onetime-use-tool',
  fp: 'print-text',
  frd: 'print-text',
  lw: 'onetime-use-tool',
  ms: 'print-text',
  msj: 'multi-use-tool',
  mst: 'onetime-use-tool',
  nt: 'multi-use-tool',
  nt2: 'multi-use-tool',
  nt3: 'multi-use-tool',
  os: 'onetime-use-tool',
  pd: 'onetime-use-tool',
  prcx: 'print-text',
  ps: 'onetime-use-tool',
  qs: 'question-sets',
  re: 'assignment',
  rf: 'multi-use-tool',
  rg: 'reference',
  rnt: 'multi-use-tool',
  sr: 'print-text',
  st: 'onetime-use-tool',
  so: 'onetime-use-tool',
  to: 'print-text',
  tom: 'print-text',
  to_student: 'print-text',
  va: 'onetime-use-tool',
  vc: 'onetime-use-tool',
  vj: 'multi-use-tool',
  vl: 'multi-use-tool',
  wh: 'onetime-use-tool',
  wm: 'onetime-use-tool',
  wt: 'onetime-use-tool',
  // Generated content types for materials
  gdoc: 'gdrive',
  pdf: 'pdf',
};

export const MATERIAL_TYPES = ['pdf', 'gdoc'];

export const materialIcon = (iconDef, iconTitle, iconType = 'material') => (
  <svg
    className={`o-od-icon-image o-od-icon-image--${iconType}`}
    aria-labelledby={iconTitle}
    role="img"
  >
    <title>{iconTitle}</title>
    <use
      className="o-od-icon-image--visible"
      href={`${iconMaterials}#icon-${iconDef}`}
    />
    <use
      className="o-od-icon-image--hover"
      href={`${iconMaterials}#icon-hover`}
    />
  </svg>
);

export const renderNoMaterials = (messagetype, resourceType) => (
  <div className="o-od-materials-blank u-od-txt--materials text-center">
    <img src={imgNoMaterials} alt="No texts" />
    <p>
      {resourceType !== undefined
        ? I18n.t(`ui.messages.no_${messagetype}s`, { type: resourceType })
        : I18n.t(`ui.messages.no_${messagetype}s_light`)}
    </p>
  </div>
);
