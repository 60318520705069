import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'utils/i18n.js.erb';
import { rawHtml } from 'utils/Formatters';

const SectionCulminatingTask = ({ resource }) => {
  return (
    <div className="row u-od-full-width">
      <aside className="o-od-panel__aside o-od-panel__aside--section">
        <h2>{I18n.t('ui.section.tabs.culminating_task')}</h2>
      </aside>
      <section className="o-od-panel__content">
        {rawHtml(
          resource.guidebook.metadata.culminatingTask,
          'u-od-txt--content'
        )}
      </section>
    </div>
  );
};

SectionCulminatingTask.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default SectionCulminatingTask;
