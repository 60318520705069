import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';
import { isStudentSubdomain } from '../../routes';
import { useIwu } from '../../hooks';
import Error from 'components/Error';
import HomeItems from './HomeItems';

const Home = ({ devise, data }) => {
  const isIwu = useIwu();
  const subject = isIwu ? 'iwu' : 'ela';
  if (
    isEmpty(data.grades) ||
    get(data, 'grades[0].hierarchy.subject') !== subject
  )
    return null;

  return (
    <main className="o-od-sticky__container--top u-od-cs-bg--light">
      <div className="row o-od-page__wrap">
        <Error />
      </div>
      <HomeItems data={data} isStudent={isStudentSubdomain(devise)} />
    </main>
  );
};

Home.propTypes = {
  data: PropTypes.object.isRequired,
  devise: PropTypes.object.isRequired,
};

export default Home;
