import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, filter } from 'lodash';
import { isStudentSubdomain } from '../../routes';
import GradeGuidebooks from './GradeGuidebooks';
import GradeProgramResources from './GradeProgramResources';

const filteredGuidebooks = (guidebooks, type) =>
  filter(guidebooks, ['guidebookType', type]);

const Grade = ({ resource, devise }) => {
  if (isEmpty(resource) || get(resource, 'odellCurriculumType') !== 'grade')
    return null;
  const isStudent = isStudentSubdomain(devise);
  const guidebooks = resource.children;
  const foundationGuidebooks = filteredGuidebooks(guidebooks, 'foundation');
  const developmentGuidebooks = filteredGuidebooks(guidebooks, 'development');
  const applicationGuidebooks = filteredGuidebooks(guidebooks, 'application');
  const scienceGuidebooks = filteredGuidebooks(guidebooks, 'science');
  const socialstudiesGuidebooks = filteredGuidebooks(
    guidebooks,
    'social-studies'
  );
  const programResources = resource.programResources;
  return (
    <div className="columns u-od-padding-top--base u-od-padding-bottom--huge">
      {!isEmpty(programResources) && !isStudent && (
        <GradeProgramResources programResources={programResources} />
      )}
      {!isEmpty(scienceGuidebooks) && (
        <GradeGuidebooks
          guidebookType="science"
          guidebooks={scienceGuidebooks}
        />
      )}
      {!isEmpty(socialstudiesGuidebooks) && (
        <GradeGuidebooks
          guidebookType="social-studies"
          guidebooks={socialstudiesGuidebooks}
        />
      )}
      {!isEmpty(foundationGuidebooks) && (
        <GradeGuidebooks
          guidebookType="foundation"
          guidebooks={foundationGuidebooks}
        />
      )}
      {!isEmpty(developmentGuidebooks) && (
        <GradeGuidebooks
          guidebookType="development"
          guidebooks={developmentGuidebooks}
        />
      )}
      {!isEmpty(applicationGuidebooks) && (
        <GradeGuidebooks
          guidebookType="application"
          guidebooks={applicationGuidebooks}
        />
      )}
    </div>
  );
};

Grade.propTypes = {
  devise: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
};

export default Grade;
